import { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';

import { getBettingRouter, useBettingRoutes } from '@gaming1/g1-betting-web';
import { getCmsRouter, useCmsRoutes } from '@gaming1/g1-cms-web';
import { useI18n } from '@gaming1/g1-core';
import {
  catchMovedURL,
  getCoreRouter,
  NotFoundPage,
  RouterWrapper,
  RouteWrapper,
  useCoreRoutes,
} from '@gaming1/g1-core-web';
import { ErrorBoundary } from '@gaming1/g1-error-management/web';
import { getLoyaltyRouter, useLoyaltyRoutes } from '@gaming1/g1-loyalty-web';
import { getPaymentRouter, usePaymentRoutes } from '@gaming1/g1-payment-web';
import { getProfileRouter } from '@gaming1/g1-player-profile/web';
import { useProfileRoutes } from '@gaming1/g1-player-profile-api';
import { RouteList } from '@gaming1/g1-routing';
import { getUserRouter, useUserRoutes } from '@gaming1/g1-user-web';
import { lazifyComponent } from '@gaming1/g1-utils';

import { movedUrls } from './urlManifests';

const LazyHomePage = lazifyComponent(
  'HomePage',
  () => import('./pages/HomePage'),
);

export const Router: FC = () => {
  const { values } = useI18n();

  const bettingRoutes = useBettingRoutes();
  const cmsRoutes = useCmsRoutes();
  const coreRoutes = useCoreRoutes();
  const loyaltyRoutes = useLoyaltyRoutes();
  const paymentRoutes = usePaymentRoutes();
  const profileRoutes = useProfileRoutes();
  const userRoutes = useUserRoutes();

  // Those routes will be accessible by their page name ('/?pagename=deposit' -> redirect to '/deposit')
  const routes: RouteList<string> = useMemo(
    () => ({
      ...bettingRoutes,
      ...cmsRoutes,
      ...coreRoutes,
      ...loyaltyRoutes,
      ...paymentRoutes,
      ...profileRoutes,
      ...userRoutes,
    }),
    [
      bettingRoutes,
      cmsRoutes,
      coreRoutes,
      loyaltyRoutes,
      paymentRoutes,
      profileRoutes,
      userRoutes,
    ],
  );

  return (
    <ErrorBoundary>
      <RouterWrapper routes={routes}>
        <Helmet titleTemplate={`%s | ${values.siteName}`} />
        <Switch>
          {/* HomePage + redirection by page name */}
          <Route exact path={`/:locale${coreRoutes.home.path}`}>
            <RouteWrapper section="default">
              <LazyHomePage />
            </RouteWrapper>
          </Route>
          {/* Imported routers */}
          {getCoreRouter(coreRoutes)}
          {getBettingRouter(bettingRoutes)}
          {getCmsRouter(cmsRoutes)}
          {getLoyaltyRouter(loyaltyRoutes)}
          {getPaymentRouter(paymentRoutes)}
          {getProfileRouter(profileRoutes)}
          {getUserRouter(userRoutes)}
          {/* 301 catcher */}
          {catchMovedURL(movedUrls)}
          {/* Nothing found this is a 404 */}
          <Route path={undefined}>
            <RouteWrapper section="default">
              <NotFoundPage />
            </RouteWrapper>
          </Route>
        </Switch>
      </RouterWrapper>
    </ErrorBoundary>
  );
};
