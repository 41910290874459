import { FC, useContext } from 'react';

import { OddsUnits, useBettingRoutePath } from '@gaming1/g1-betting-web';
import { useCmsRoutePath } from '@gaming1/g1-cms-web';
import { useConfig } from '@gaming1/g1-config';
import { DrawerNav, MenuGroups } from '@gaming1/g1-layout/web';
import { useLoyaltyRoutePath } from '@gaming1/g1-loyalty-web';
import { ZendeskWidgetContext } from '@gaming1/g1-support-widget/web';
import { Box } from '@gaming1/g1-ui';

import { FOOTER_LOGO_IMG_HEIGHT } from '../OverwriteStyle';

import { AppFooter } from './AppFooter';

export const AppDrawerNav: FC = () => {
  const config = useConfig();
  const getBettingPath = useBettingRoutePath();
  const getCmsPath = useCmsRoutePath();
  const getLoyaltyPath = useLoyaltyRoutePath();
  const { showWidget } = useContext(ZendeskWidgetContext);

  const mobileMenuItems: MenuGroups = [
    [
      {
        label: 'menu.sport',
        path: getBettingPath('betting'),
        testId: 'drawernav-betting',
        icon: 'AmericanFootball',
      },
      {
        label: 'menu.shop',
        path: getLoyaltyPath('shop'),
        testId: 'drawernav-shop',
        icon: 'Shop',
      },
      {
        label: 'menu.promotions',
        path: getCmsPath('allPromotions'),
        testId: 'drawernav-promotions',
        icon: 'BettingPromotion',
      },
      {
        label: 'menu.tournaments',
        path: getBettingPath('tournamentor', { type: '' }),
        testId: 'drawernav-tournaments',
        icon: 'Cup',
        hidden: !config.betting.tournamentor.enabled,
      },
      {
        label: 'menu.blog',
        path: getCmsPath('blog'),
        testId: 'drawernav-blog',
        icon: 'Blog',
      },
    ],
    [
      {
        icon: 'HelpCenter',
        label: 'menu.help',
        onClick: showWidget,
        testId: 'topbar-help',
      },
    ],
  ];

  return (
    <DrawerNav>
      <DrawerNav.ContentContainer footerLogoHeight={FOOTER_LOGO_IMG_HEIGHT}>
        <DrawerNav.Menu menu={mobileMenuItems} />
        <Box mt="auto" mb="md">
          <OddsUnits type="radio" />
        </Box>
      </DrawerNav.ContentContainer>
      <AppFooter />
    </DrawerNav>
  );
};
