import React, { FC, useState } from 'react';

import { BettingSearchResult } from '@gaming1/g1-betting-web';
import { useTranslation } from '@gaming1/g1-i18n';
import { SearchDrawer } from '@gaming1/g1-search/web';

export const AppSearchDrawer: FC = () => {
  const { t } = useTranslation('betting');

  const [bettingFailureTerm, setBettingFailureTerm] = useState<string | null>(
    null,
  );

  return (
    <SearchDrawer
      noResultTerm={bettingFailureTerm}
      inputPlaceHolder={t('search.placeholder')}
      minCharacters={3}
    >
      <BettingSearchResult
        key="bettingResult"
        onTermFailure={setBettingFailureTerm}
        onRequestFailure={() => undefined}
      />
    </SearchDrawer>
  );
};
