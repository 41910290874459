import React, { ContextType, FC, ReactNode } from 'react';

import { IconContext } from '@gaming1/g1-ui';

const IconsContextValue: ContextType<typeof IconContext> = {
  types: {},
  ids: {
    'amount-currency': 'CurrencyDollar',
    'deposit-info-currency': 'CurrencyDollar',
    'footer-betting-age': 'Circle21Plus',
    'limits-currency': 'CurrencyDollar',
    'list-message-icon-sport': 'SportsAZUsa',
    'profile-scroll-menu-opencirclecheck': 'ResponsibleGaming',
  },
};

export const IconsProvider: FC<{ children?: ReactNode }> = ({ children }) => (
  <IconContext.Provider value={IconsContextValue}>
    {children}
  </IconContext.Provider>
);
