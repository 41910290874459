import React, { FC, ReactNode } from 'react';

import { GlobalAssetsProvider } from '@gaming1/g1-assets-management/web';

import { accountAuthenticationAssets } from './account-authentication/accountAuthenticationAssets';
import { bettingAssets } from './betting/bettingAssets';
import { coreAssets } from './core/coreAssets';
import { loyaltyAssets } from './loyalty/loyaltyAssets';
import { paymentAssets } from './payment/paymentAssets';
import { userAssets } from './user/userAssets';

const assets = [
  accountAuthenticationAssets,
  bettingAssets,
  coreAssets,
  loyaltyAssets,
  paymentAssets,
  userAssets,
];

export const AssetsProvider: FC<{ children?: ReactNode }> = ({ children }) => (
  <GlobalAssetsProvider contextValues={assets}>{children}</GlobalAssetsProvider>
);
