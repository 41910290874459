import { getTheme, Theme } from '@gaming1/g1-style';

export const theme: Theme = getTheme(
  {
    dark: true,
    name: 'dark',
    colors: {
      activeLink: '#E4E417',
      backgrounds: ['#1d242f', '#293342', '#354255', '#4e5b6F', '#757f8f'],
      backgroundDark: '#232c39',
      backgroundLight: '#fefefe',
      clubStatus: '#E4E417',
      danger: '#F33D41',
      deposit: '#E4E417',
      neutrals: [
        '#ffffffe0',
        '#ffffffa3',
        '#ffffff7a',
        '#ffffff4d',
        '#ffffff1f',
      ],
      info: '#44a8e5',
      link: '#ffff1d',
      muted: '#94A7B3',
      portal: '#E4E417',
      primary: [
        '#E4E417',
        '#E4E417',
        '#E4E417',
        '#E4E417',
        '#E4E417',
        '#E4E417',
      ],
      secondary: [
        '#3B495F',
        '#3B495F',
        '#3B495F',
        '#3B495F',
        '#3B495F',
        '#3B495F',
      ],
      tertiary: '#94A7B3',
      success: '#5ac732',
      text: '#EBECEF',
      textDark: '#3B495F',
      textLight: '#ffffff',
      warning: '#ec5d00',
    },
    shadows: [
      'none',
      'none',
      '0 4px 7px 0 rgba(1,10,17,0.60)',
      '0 4px 7px 0 rgba(1,10,17,0.60)',
      '0 4px 7px 0 rgba(1,10,17,0.60)',
    ],
    fontFamilies: {
      content: {
        bold: 'Montserrat',
        light: 'Montserrat',
        regular: 'Montserrat',
      },
      special: {
        bold: 'Montserrat',
        light: 'Montserrat',
        regular: 'Montserrat',
      },
    },
    fontWeights: {
      content: {
        bold: '700',
        light: '400',
        regular: '500',
      },
      special: {
        bold: '700',
        light: '400',
        regular: '500',
      },
    },
    fontSizes: [12, 14, 16, 20, 24, 32],
    options: {
      buttonsFontSize: '14px',
      buttonsFontWeight: 'bold',
      contentBorderWidth: '1px',
      heading1FontSize: '29px',
      heading1FontWeight: 'bold',
      heading1Uppercase: false,
      heading2FontSize: '21px',
      heading2FontWeight: 'light',
      heading2Uppercase: false,
      heading3FontSize: '18px',
      heading3FontWeight: 'bold',
      heading3Uppercase: false,
      heading4FontSize: '16px',
      heading4FontWeight: 'bold',
      heading4Uppercase: false,
      roundBorders: true,
    },
  },
  () => ({
    colors: {
      bettingBettingSlipMenuBackground: '#1d242f',
      bettingOutcome: '#ffffff',
      bettingOutcomeActive: '#E4E417',
      bettingOutcomeBorder: '#ffffff',
      bettingOutcomeBorderActive: '#E4E417',
      bettingOutcomeLocked: '#232c39',
      bettingOutcomeLockedBorder: '#232c39',
      bettingOutcomeActiveHovered: '#F5F536',
      bettingOutcomeHovered: '#DFE4EC',
      biggestWinningsBorder: '#E4E417',
      bottomNavActiveText: '#E4E417',
      bottomNavBackground: '#354255',
      bottomNavHighlightedBackground: '#E4E417',
      bottomNavHighlightedText: '#3B495F',
      bottomNavText: '#ffffff',
      buttonDepositText: '#3B495F',
      buttonPrimaryBackground: '#E4E417',
      buttonPrimaryBackgroundActive: '#ffff18',
      buttonPrimaryBorder: '#E4E417',
      buttonPrimaryBorderActive: '#ffff18',
      buttonPrimaryText: '#3B495F',
      buttonPrimaryTextActive: '#3B495F',
      buttonSecondaryBackground: 'transparent',
      buttonSecondaryBackgroundActive: 'transparent',
      buttonSecondaryBorder: '#E4E417',
      buttonSecondaryBorderActive: '#ffff19',
      buttonSecondaryText: '#E4E417',
      buttonSecondaryTextActive: '#ffff19',
      buttonTertiaryBackground: 'transparent',
      buttonTertiaryBackgroundActive: '#89890e',
      buttonTertiaryBorder: 'transparent',
      buttonTertiaryBorderActive: 'transparent',
      buttonTertiaryText: '#E4E417',
      buttonTertiaryTextActive: '#E4E417',
      chips: '#94A7B3',
      contentBorder: '#ccc',
      drawerNavActiveText: '#E4E417',
      drawerNavBackground: '#232C39',
      drawerNavText: '#ffffff',
      favouriteIconColor: '#E4E417',
      footerAltText: '#9DA4AF',
      footerBorder: '#293342',
      footerIconColor: '#94A7B3',
      footerLink: '#E4E417',
      footerActiveLink: '#ffff1b',
      footerText: '#ffffff',
      headerBackground: '#354255',
      heading: '#EBECEF',
      inputBackground: '#4e5b6F',
      inputBorder: '#EBECEF',
      jackpot: '#E4E417',
      jackpotBronze: '#e06f16',
      jackpotCopper: '#e33b10',
      jackpotGold: '#fbc400',
      jackpotSilver: '#b8b8ba',
      label: '#D8DBDF',
      mainBackground: '#1D242F',
      profileBorder: '#3B495F',
      profileIconBackgroundColor: '#232C39',
      profileIconBorderColor: '#94A7B3',
      pricing: '#E4E417',
      scrollNavBackground: '#1D242F',
      scrollNavBorder: '#3B495F',
      scrollNavButtonBackground: '#ffffff',
      scrollNavButtonForeground: '#3B495F',
      scrollNavItemBorderActive: '#E4E417',
      scrollNavItemBorderHovered: '#E4E417',
      scrollNavItemForeground: '#ffffff',
      scrollNavItemForegroundActive: '#E4E417',
      scrollNavItemForegroundHighlighted: '#E4E417',
      scrollNavTileBackground: '#354255',
      scrollNavTileBackgroundActive: '#4e5b6F',
      scrollNavFlexTileBackground: '#354255',
      scrollNavFlexTileBackgroundActive: '#4e5b6F',
      separator: '#3B495F',
      topBarActiveText: '#E4E417',
      topBarBackground: '#1D242F',
      topBarBorder: '#354255',
      topBarText: '#C4C8CF',
      topNavActiveText: '#E4E417',
      topNavBackground: '#232C39',
      topNavText: '#ffffff',
      tournamentCardBorder: '#293342',
      tournamentCardSecondaryText: '#C4C8CF',
      tournamentCategoryTag: '#94A7B3',
      tournamentCategoryTagBestOdd: '#E4E417',
      tournamentLabelBackground: '#3B495F',
    },
  }),
);
