/* eslint-disable @typescript-eslint/no-explicit-any */
export interface WindowWithReactDevTools extends Window {
  __REACT_DEVTOOLS_GLOBAL_HOOKS__?: any;
  TestCafe?: any;
}

export type WindowWithParentReactDevTools = {
  parent: {
    __REACT_DEVTOOLS_GLOBAL_HOOKS__?: any;
  };
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export type WindowWithBothReactDevTools = WindowWithReactDevTools &
  WindowWithParentReactDevTools;

const augmentedWindow = window as WindowWithBothReactDevTools;

/* eslint-disable no-underscore-dangle */
if (
  augmentedWindow.TestCafe &&
  augmentedWindow.parent &&
  augmentedWindow.parent.__REACT_DEVTOOLS_GLOBAL_HOOKS__
) {
  augmentedWindow.__REACT_DEVTOOLS_GLOBAL_HOOKS__ =
    augmentedWindow.parent.__REACT_DEVTOOLS_GLOBAL_HOOKS__;
}
/* eslint-enable no-underscore-dangle */
