import { ComponentProps, FC, ReactNode, Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AnalyticsWatchers } from '@gaming1/g1-analytics/web';
import {
  bettingDebugTabs,
  BettingProviders,
  BettingWatchers,
} from '@gaming1/g1-betting-web';
import { currentSectionSelector } from '@gaming1/g1-core';
import { CoreWatchers } from '@gaming1/g1-core-web';
import { DebugPanel } from '@gaming1/g1-debugging/web';
import { InboxWatchers } from '@gaming1/g1-inbox-web';
import { Content, MainContainer, SuspenseLoader } from '@gaming1/g1-layout/web';
import { Backdrop, BannersContainer, LoaderContext } from '@gaming1/g1-ui';
import { UserProviders, UserWatchers } from '@gaming1/g1-user-web';
import { WalletWatchers } from '@gaming1/g1-wallet/web';

import { AppBottomNav } from './AppBottomNav';
import { AppDrawerNav } from './AppDrawerNav';
import { AppFooter } from './AppFooter';
import { AppLoader } from './AppLoader';
import { AppSearchDrawer } from './AppSearchDrawer';
import { AppTopBar } from './AppTopBar';
import { AppTopNav } from './AppTopNav';

const additionalDebugTabs = [...bettingDebugTabs];

const loaders = {
  default: () => <AppLoader height="3em" width="3em" />,
  page: () => <AppLoader height="10em" width="10em" />,
};

export const AppLayout: FC<{ children?: ReactNode }> = ({ children }) => {
  const currentSection = useSelector(currentSectionSelector);

  const bettingMenus: ComponentProps<typeof BettingProviders>['menus'] =
    useMemo(
      () => ({
        main: { firstItems: [], lastItems: [] },
        mainAuthenticated: { firstItems: [], lastItems: [] },
        services: [],
      }),
      [],
    );

  return (
    <MainContainer>
      <AnalyticsWatchers />
      <BettingProviders menus={bettingMenus} footer={AppFooter}>
        <UserProviders>
          <LoaderContext.Provider value={loaders}>
            <AppTopBar />
            <AppTopNav />
            <BannersContainer />
            <Suspense fallback={<SuspenseLoader />}>
              <Content>{children}</Content>
            </Suspense>
            <Suspense fallback={null}>
              <DebugPanel additionalTabs={additionalDebugTabs} />
              <CoreWatchers />
              <BettingWatchers />
              <InboxWatchers />
              <UserWatchers />
              {currentSection !== 'betting' && <AppFooter />}
              <AppBottomNav />
              <Backdrop />
              {/* Drawers */}
              <AppDrawerNav />
              <AppSearchDrawer />
              <WalletWatchers />
            </Suspense>
          </LoaderContext.Provider>
        </UserProviders>
      </BettingProviders>
    </MainContainer>
  );
};
