import { FC, useContext } from 'react';

import { useCmsRoutePath } from '@gaming1/g1-cms-web';
import { LastConnection, MenuGroups, TopBar } from '@gaming1/g1-layout/web';
import { ZendeskWidgetContext } from '@gaming1/g1-support-widget/web';
import { RenderOnMedia } from '@gaming1/g1-ui';

export const AppTopBar: FC = () => {
  const getCmsPath = useCmsRoutePath();

  const { showWidget } = useContext(ZendeskWidgetContext);

  const topBarMenuItems: MenuGroups = [
    [
      {
        label: 'menu.blog',
        path: getCmsPath('blog'),
        testId: 'topbar-blog',
        icon: 'Blog',
      },
      {
        icon: 'HelpCenter',
        label: 'menu.help',
        onClick: showWidget,
        testId: 'topbar-help',
      },
    ],
  ];

  return (
    <RenderOnMedia min="lg">
      <TopBar>
        <TopBar.Clock />
        <TopBar.Separator />
        <LastConnection />
        <TopBar.Menu menu={topBarMenuItems} />
      </TopBar>
    </RenderOnMedia>
  );
};
