import React, { FC } from 'react';

export const Briefcase: FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 40 40">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M23,4.5 C25.9634791,4.5 28.3795391,6.84378001 28.4956328,9.77879286 L28.5,10 L28.5,10.5 L36,10.5 C37.8685634,10.5 39.3951264,11.9642776 39.4948211,13.8079648 L39.5,14 L39.5,32 C39.5,33.9329966 37.9329966,35.5 36,35.5 L36,35.5 L4,35.5 C2.06700338,35.5 0.5,33.9329966 0.5,32 L0.5,32 L0.5,14 C0.5,12.0670034 2.06700338,10.5 4,10.5 L4,10.5 L11.5,10.5 L11.5,10 C11.5,7.03652085 13.84378,4.62046086 16.7787929,4.5043672 L17,4.5 L23,4.5 Z M20,26 C14.4997042,26 8.9997643,25.5855725 3.50078543,24.756872 L3.5,32 C3.5,32.2761424 3.72385763,32.5 4,32.5 L4,32.5 L36,32.5 C36.2761424,32.5 36.5,32.2761424 36.5,32 L36.5,32 L36.5002148,24.7567213 C31.0009026,25.5855223 25.5006293,26 20,26 Z M36,13.5 L4,13.5 C3.72385763,13.5 3.5,13.7238576 3.5,14 L3.5,14 L3.5003219,21.7219876 C9.00102865,22.5740518 14.5007023,23 20,23 C25.4996308,23 30.9996377,22.5740002 36.5006779,21.7218327 L36.5,14 C36.5,13.7238576 36.2761424,13.5 36,13.5 L36,13.5 Z M20,18 C20.8284271,18 21.5,18.6715729 21.5,19.5 C21.5,20.3284271 20.8284271,21 20,21 C19.1715729,21 18.5,20.3284271 18.5,19.5 C18.5,18.6715729 19.1715729,18 20,18 Z M23,7.5 L17,7.5 C15.6745166,7.5 14.5899613,8.53153594 14.5053177,9.83562431 L14.5,10 L14.5,10.5 L25.5,10.5 L25.5,10 C25.5,8.6745166 24.4684641,7.58996133 23.1643757,7.50531768 L23,7.5 Z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export const Envelope: FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 40 40">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect
        stroke="#9DA4AF"
        strokeWidth="3"
        x="2"
        y="6"
        width="36"
        height="28"
        rx="2"
      />
      <polyline
        stroke="#9DA4AF"
        strokeWidth="3"
        strokeLinejoin="round"
        points="2 9 20 22 38 9"
      />
    </g>
  </svg>
);

export const Headset: FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 40 40">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M20,2.5 C28.4285396,2.5 35.3234593,8.80335581 35.4966637,16.6956757 L35.5,17 L35.5,26 C35.5,27.7187259 34.7116368,29.2533197 33.4767986,30.2618933 C33.4514533,30.4084876 33.4041777,30.5481851 33.3388122,30.6771632 L33.291,30.76 L33.2620634,30.8814137 C32.4466688,34.0378077 29.6277585,36.3885181 26.2427311,36.4961464 L26,36.5 L23.9994531,36.5009713 C23.5433054,37.1076422 22.8174727,37.5 22,37.5 L22,37.5 L18,37.5 C16.6192881,37.5 15.5,36.3807119 15.5,35 C15.5,33.6192881 16.6192881,32.5 18,32.5 L18,32.5 L22,32.5 C22.8179292,32.5 23.5441159,32.8927961 24.0002169,33.5000451 L26,33.5 C27.5628815,33.5 28.939675,32.7032623 29.7463703,31.4937969 C26.8261547,31.3613282 24.5,28.9523154 24.5,26 L24.5,26 L24.5,22 C24.5,18.9624339 26.9624339,16.5 30,16.5 C30.9003795,16.5 31.7502268,16.7163537 32.5003901,17.099909 L32.5,17 C32.5,10.6745801 26.9273752,5.5 20,5.5 C13.1791998,5.5 7.67178603,10.516587 7.50394153,16.7088894 L7.5,17 L7.50062188,17.0993917 C8.25053745,16.7161591 9.10002539,16.5 10,16.5 C13.0375661,16.5 15.5,18.9624339 15.5,22 L15.5,22 L15.5,26 C15.5,29.0375661 13.0375661,31.5 10,31.5 C6.96243388,31.5 4.5,29.0375661 4.5,26 L4.5,26 L4.5,17 C4.5,8.96601637 11.4634022,2.5 20,2.5 Z M30,19.5 C28.6192881,19.5 27.5,20.6192881 27.5,22 L27.5,22 L27.5,26 C27.5,27.3807119 28.6192881,28.5 30,28.5 C31.3807119,28.5 32.5,27.3807119 32.5,26 L32.5,26 L32.5,22 C32.5,20.6192881 31.3807119,19.5 30,19.5 Z M10,19.5 C8.61928813,19.5 7.5,20.6192881 7.5,22 L7.5,22 L7.5,26 C7.5,27.3807119 8.61928813,28.5 10,28.5 C11.3807119,28.5 12.5,27.3807119 12.5,26 L12.5,26 L12.5,22 C12.5,20.6192881 11.3807119,19.5 10,19.5 Z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export const List: FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 40 40">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M33,28.5 C33.8284271,28.5 34.5,29.1715729 34.5,30 C34.5,30.7796961 33.9051119,31.4204487 33.14446,31.4931334 L33,31.5 L13,31.5 C12.1715729,31.5 11.5,30.8284271 11.5,30 C11.5,29.2203039 12.0948881,28.5795513 12.85554,28.5068666 L13,28.5 L33,28.5 Z M7.5,28.5 C8.32842712,28.5 9,29.1715729 9,30 C9,30.8284271 8.32842712,31.5 7.5,31.5 C6.67157288,31.5 6,30.8284271 6,30 C6,29.1715729 6.67157288,28.5 7.5,28.5 Z M33,18.5 C33.8284271,18.5 34.5,19.1715729 34.5,20 C34.5,20.7796961 33.9051119,21.4204487 33.14446,21.4931334 L33,21.5 L13,21.5 C12.1715729,21.5 11.5,20.8284271 11.5,20 C11.5,19.2203039 12.0948881,18.5795513 12.85554,18.5068666 L13,18.5 L33,18.5 Z M7.5,18.5 C8.32842712,18.5 9,19.1715729 9,20 C9,20.8284271 8.32842712,21.5 7.5,21.5 C6.67157288,21.5 6,20.8284271 6,20 C6,19.1715729 6.67157288,18.5 7.5,18.5 Z M33,8.5 C33.8284271,8.5 34.5,9.17157288 34.5,10 C34.5,10.7796961 33.9051119,11.4204487 33.14446,11.4931334 L33,11.5 L13,11.5 C12.1715729,11.5 11.5,10.8284271 11.5,10 C11.5,9.22030388 12.0948881,8.57955132 12.85554,8.50686658 L13,8.5 L33,8.5 Z M7.5,8.5 C8.32842712,8.5 9,9.17157288 9,10 C9,10.8284271 8.32842712,11.5 7.5,11.5 C6.67157288,11.5 6,10.8284271 6,10 C6,9.17157288 6.67157288,8.5 7.5,8.5 Z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export const Smile: FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 40 40">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M20,0.5 C30.7695526,0.5 39.5,9.23044738 39.5,20 C39.5,30.7695526 30.7695526,39.5 20,39.5 C9.23044738,39.5 0.5,30.7695526 0.5,20 C0.5,9.23044738 9.23044738,0.5 20,0.5 Z M20,3.5 C10.8873016,3.5 3.5,10.8873016 3.5,20 C3.5,29.1126984 10.8873016,36.5 20,36.5 C29.1126984,36.5 36.5,29.1126984 36.5,20 C36.5,10.8873016 29.1126984,3.5 20,3.5 Z M26.8115653,24.4559902 C27.4093039,25.0295755 27.428884,25.9791211 26.8552987,26.5768597 C25.0761065,28.4309725 22.6214196,29.5 20,29.5 C17.4259568,29.5 15.0116901,28.4694192 13.2391336,26.6738823 C12.657132,26.0843354 12.6632487,25.1346076 13.2527957,24.552606 C13.8423426,23.9706044 14.7920704,23.9767211 15.374072,24.5662681 C16.5885635,25.7965048 18.2365904,26.5 20,26.5 C21.7957931,26.5 23.4715611,25.7701952 24.6906958,24.4997236 C25.2642811,23.901985 26.2138267,23.8824049 26.8115653,24.4559902 Z M27,13.5 C28.1045695,13.5 29,14.3954305 29,15.5 C29,16.6045695 28.1045695,17.5 27,17.5 C25.8954305,17.5 25,16.6045695 25,15.5 C25,14.3954305 25.8954305,13.5 27,13.5 Z M13,13.5 C14.1045695,13.5 15,14.3954305 15,15.5 C15,16.6045695 14.1045695,17.5 13,17.5 C11.8954305,17.5 11,16.6045695 11,15.5 C11,14.3954305 11.8954305,13.5 13,13.5 Z"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
