import React, { ContextType, FC, ReactNode } from 'react';

import { ImageContext } from '@gaming1/g1-assets-management/web';

import noResults from '../assets/custom/search/no-result.svg';
import tournamentLost from '../assets/custom/tournaments/lost.svg';
import tournamentWon from '../assets/custom/tournaments/win.svg';

const imagesContextValue: ContextType<typeof ImageContext> = {
  // Search
  'search-no-result': noResults,
  // Tournaments
  'info-panel-lost': tournamentLost,
  'info-panel-won': tournamentWon,
};

export const ImagesProvider: FC<{ children?: ReactNode }> = ({ children }) => (
  <ImageContext.Provider value={imagesContextValue}>
    {children}
  </ImageContext.Provider>
);
