import { FC, useContext } from 'react';

import { Image } from '@gaming1/g1-assets-management/web';
import { useCmsRoutePath } from '@gaming1/g1-cms-web';
import { useI18n } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { Footer, MenuGroups } from '@gaming1/g1-layout/web';
import { getWrapperEnv } from '@gaming1/g1-logger';
import { useZendeskUrl } from '@gaming1/g1-support-widget/shared';
import { ZendeskWidgetContext } from '@gaming1/g1-support-widget/web';
import { SimpleButton, Text, useMediaBreakPoint } from '@gaming1/g1-ui';

import delawareNorth from '../../assets/custom/footer/delaware-north.png';
import southlandCasino from '../../assets/custom/footer/southland-casino.png';
import tnRedline from '../../assets/custom/footer/tn-redline.png';
import { Envelope, Headset } from '../../assets/custom/svgs';

export const AppFooter: FC = () => {
  const { t } = useTranslation('core');
  const getCmsPath = useCmsRoutePath();
  const { values } = useI18n();

  const zendeskUrl = useZendeskUrl();
  const { showWidget } = useContext(ZendeskWidgetContext);
  const isDesktop = useMediaBreakPoint({ min: 'lg' });

  const footerSupportItems: MenuGroups = [
    [
      {
        icon: <Envelope />,
        label: 'footer.link.support',
        path: `mailto:${values.supportEmail}`,
        testId: 'footer-support',
        isExternalLink: true,
      },
      {
        icon: <Headset />,
        label: '901-554-4988',
        path: 'tel:901-554-4988',
        testId: 'footer-phone',
        isExternalLink: true,
      },
    ],
  ];

  const footerInfosItems: MenuGroups = [
    [
      {
        label: 'footer.link.blog',
        path: getCmsPath('blog'),
        testId: 'footer-blog',
      },
      {
        label: 'footer.link.faq',
        path: `${zendeskUrl}`,
        testId: 'footer-faq',
        isExternalLink: true,
      },
      {
        label: 'footer.responsible',
        path: getCmsPath('nav', {
          navUid: 'about-us',
          contentUid: 'responsible-gaming',
        }),
        testId: 'footer-responsible-gaming',
      },
      {
        label: 'footer.careers',
        path: 'https://careers.delawarenorth.com/search-results?keywords=sportsbook',
        testId: 'footer-careers',
        isExternalLink: true,
      },
    ],
  ];

  const footerOtherInfosItems: MenuGroups = [
    [
      {
        label: 'footer.link.accessibility',
        path: 'https://www.delawarenorth.com/accessibility',
        testId: 'footer-accessibility',
        isExternalLink: true,
      },
      {
        label: 'footer.link.corporateStructure',
        path: 'https://www.delawarenorth.com/corporate-structure',
        testId: 'footer-corporate-structure',
        isExternalLink: true,
      },
      {
        label: 'footer.link.aboutDelawareNorth',
        path: 'https://www.delawarenorth.com/industries/gaming',
        testId: 'footer-about-delaware-north',
        isExternalLink: true,
      },
    ],
  ];

  const footerLegalItems: MenuGroups = [
    [
      {
        label: 'footer.link.copyright',
        path: 'https://www.delawarenorth.com/copyright',
        testId: 'footer-copyright',
        isExternalLink: true,
      },
      {
        label: 'footer.link.privacy',
        path: getCmsPath('nav', {
          navUid: 'about-us',
          contentUid: 'privacy-policy',
        }),
        testId: 'footer-privacy',
      },
      {
        label: 'footer.link.term',
        path: getCmsPath('nav', {
          navUid: 'about-us',
          contentUid: 'terms-of-service',
        }),
        testId: 'footer-term',
      },
      {
        label: 'footer.link.houseRules',
        path: getCmsPath('nav', {
          navUid: 'about-us',
          contentUid: 'house-rules',
        }),
        testId: 'footer-house-rules',
      },
    ],
  ];

  return (
    <Footer>
      <Footer.Header
        facebookUrl="https://www.facebook.com/betlysportsbook"
        twitterUrl="https://www.twitter.com/betlysportsbook"
        instagramUrl="https://www.instagram.com/betlysportsbook"
      />
      <Footer.Section flexWrap="wrap" hasTopBorder>
        <Footer.Box mt="none">
          <Footer.Links linkGroups={footerInfosItems} />
        </Footer.Box>
        <Footer.Box mt="none">
          <Footer.Links linkGroups={footerOtherInfosItems} />
        </Footer.Box>
        <Footer.Box mt="none">
          <Footer.Links linkGroups={footerLegalItems} />
        </Footer.Box>
        <Footer.Box mt={isDesktop ? 'xs' : 'lg'}>
          <SimpleButton onClick={showWidget} id="footer-help">
            <Footer.ImageAndText
              icon="MessageCopy"
              text={t('button.helpCenter')}
            />
          </SimpleButton>
          <Footer.Links linkGroups={footerSupportItems} />
          <Footer.ImageAndText icon="Circle21Plus" text={t('footer.betting')} />
        </Footer.Box>
        {getWrapperEnv() !== 'rn' && (
          <Footer.Box mt={isDesktop ? 'md' : 'lg'}>
            <Footer.Title fontSize="xl">{t('footer.appTitle')}</Footer.Title>
            <Footer.AppDownloadLinks />
          </Footer.Box>
        )}
      </Footer.Section>
      <Footer.Section type="row">
        <Footer.Box alignItems="center">
          <Image
            url={southlandCasino}
            id="footer-southland-casino"
            alt="southland-casino"
            width="77"
          />
        </Footer.Box>
      </Footer.Section>
      <Footer.Section>
        <Footer.Box hasBackground mb="md" alignItems="center">
          <Text fontSize="sm" lineHeight="15px" mt="none" textAlign="center">
            {t('footer.license')}
          </Text>
        </Footer.Box>
      </Footer.Section>
      <Footer.Section type="row" hasAlternativeBackground>
        <Footer.Box justifyContent="center" flexDirection="row" mt="none">
          <Image
            url={tnRedline}
            id="footer-tn-redline"
            alt="tn redline"
            height="44"
            style={{ padding: '0 16px' }}
          />
          <Image
            url={delawareNorth}
            id="footer-delaware"
            alt="delaware-north"
            height="44"
            style={{ padding: '0 16px' }}
          />
        </Footer.Box>
        {getWrapperEnv() === 'rn' && (
          <Footer.Section type="row" hasAlternativeBackground hasTopBorder>
            <Footer.AppVersion />
          </Footer.Section>
        )}
      </Footer.Section>
      <Footer.SafeArea hasAlternativeBackground />
    </Footer>
  );
};
