import { AccountAuthenticationAssetsContext } from '@gaming1/g1-account-authentication/web';
import { generateAssetsContextValue } from '@gaming1/g1-assets-management/web';

import fileNoPreview from './fileUpload/fileNoPreview.svg';
import filePreviewPdf from './fileUpload/filePreviewPdf.svg';

export const accountAuthenticationAssets = generateAssetsContextValue(
  AccountAuthenticationAssetsContext,
)({
  /* File upload */
  fileNoPreview,
  filePreviewPdf,
});
