import { ContextType, FC, ReactNode } from 'react';

import { Image } from '@gaming1/g1-assets-management/web';
import { LightFooterContext } from '@gaming1/g1-layout/web';

import tnRedline from '../assets/custom/footer/tn-redline.png';

const logoStyle = { order: 1 };

const TnRedlineLogo: FC = () => (
  <Image
    url={tnRedline}
    id="footer-southland-casino"
    alt="southland-casino"
    width="71"
    style={logoStyle}
  />
);

const lightFooterValue: ContextType<typeof LightFooterContext> = {
  desktop: {
    leftContent: [TnRedlineLogo],
  },
  mobile: {
    leftContent: [TnRedlineLogo],
  },
};

/**
 * Custom elements placed in the LightFooter.
 *
 * WARNING :
 * One can define a custom CSS `order` style property on each element.
 * This can make elements in `RightLightFooterOnMaxSMScreens` look weirdly displayed.
 * `<ItemSeparator />` SC can now be imported from `g1-core-web` and used anywhere  if required.
 */
export const LightFooterProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => (
  <LightFooterContext.Provider value={lightFooterValue}>
    {children}
  </LightFooterContext.Provider>
);
