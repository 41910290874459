import styled, { createGlobalStyle } from 'styled-components';

import { Image } from '@gaming1/g1-assets-management/web';
import {
  FooterLogoImg,
  IconProfileWrapper,
  TopNavImageWrapper,
} from '@gaming1/g1-layout/web';
import { CashWrapper } from '@gaming1/g1-payment-web';
import { colors, media, spaces } from '@gaming1/g1-style';
import { LoginHeaderLogo } from '@gaming1/g1-user-web';

const LOGIN_HEADER_LOGO_HEIGHT = 65;
export const FOOTER_LOGO_IMG_HEIGHT = 60;

/** Overwrite the css of some components from the libraries for this partner */
export const OverwriteStyle = createGlobalStyle<object>`
  /** Core web */

  ${TopNavImageWrapper} {
    height: 33px;
    margin-right: 0;
  }

  ${media.sm} {
    ${TopNavImageWrapper} {
      height: 60px;
      margin-right: ${spaces('xs')};
    }
  }

  ${FooterLogoImg} {
    height: ${FOOTER_LOGO_IMG_HEIGHT}px;
  }

  /** User web */

  ${CashWrapper} {
    background-color: ${colors('secondary')};
  }

  ${IconProfileWrapper} {
    background-color: ${colors('secondary')};
  }

  ${LoginHeaderLogo} {
    height: ${LOGIN_HEADER_LOGO_HEIGHT}px;
  }
`;

export const ResponsibleGamingImage = styled(Image)`
  margin-right: ${spaces('xs')};

  ${media.lg} {
    margin-right: ${spaces('lg')};
    margin-left: 0;
  }
`;
